import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Form, Select } from 'antd';
import { format } from 'd3-format';
import { timeFormat, timeParse } from 'd3-time-format';
import { ChartCanvas, Chart } from 'react-stockcharts';
import { CandlestickSeries, MACDSeries, AreaSeries, LineSeries } from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { CrossHairCursor, MouseCoordinateX, MouseCoordinateY } from 'react-stockcharts/lib/coordinates';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { OHLCTooltip, HoverTooltip } from 'react-stockcharts/lib/tooltip';
import { last } from 'react-stockcharts/lib/utils';

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
};

const macdAppearance = {
	stroke: {
		macd: '#FF0000',
		signal: '#00F300'
	},
	fill: {
		divergence: '#4682B4'
	}
};

const currencyFormat = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', notation: 'compact', compactDisplay: 'short', maximumSignificantDigits: 4 }).format(Number(value));

export default ({ auth, coinAPI, baseUrl }) => {
	const [division, setDivision] = useState('60');
	const [chartData, setChartData] = useState({ data: [], xScale: null, xAccessor: null, displayXAccessor: null, start: 0, end: 0, xExtents: [] });

	useEffect(async () => {
		setChartData({ data: [], xScale: null, xAccessor: null, displayXAccessor: null, start: 0, end: 0, xExtents: [] });
		handelGetTrades();
	}, [division]);

	const handelGetTrades = async () => {
		const response = await coinAPI.getTrades(auth, division);

		const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d) => timeParse('%Y/%m/%d %H:%M:%S')(moment(d.timeStamp).local().format('YYYY/MM/DD HH:mm:ss')));
		const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(response);
		const start = xAccessor(last(data));
		const end = xAccessor(data[Math.max(0, data.length - 60)]);
		const xExtents = [start, end];

		setChartData({ data, xScale, xAccessor, displayXAccessor, start, end, xExtents });
	};

	const dateFormat = timeFormat('%Y/%m/%d %H:%M:%S');
	const tooltipContent = () => {
		return ({ currentItem, xAccessor }) => {
			return {
				x: dateFormat(xAccessor(currentItem)),
				y: [
					{
						label: 'Power',
						value: currencyFormat(currentItem.power)
					},
					{
						label: 'SumPower',
						value: currencyFormat(currentItem.sumPower)
					},
					{
						label: 'Cost',
						value: currencyFormat(currentItem.cost)
					},
					{
						label: 'PushUp',
						value: currencyFormat(currentItem.pushUpAmount / currentItem.pushUpQuantity)
					},
					{
						label: 'PullDown',
						value: currencyFormat(currentItem.pullDownAmount / currentItem.pullDownQuantity)
					},
					{
						label: 'Total',
						value: currencyFormat((currentItem.pushUpAmount + currentItem.pullDownAmount) / (currentItem.pushUpQuantity + currentItem.pullDownQuantity))
					}
				]
			};
		};
	}

	return (
		<>
			<Form {...layout} name="user_form" initialValues={{ username: '60' }}>
				<Form.Item name="username" label="기간">
					<Select showSearch style={{ width: 200 }} placeholder="기간 선택" optionFilterProp="children" onChange={setDivision} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
						<Select.Option key="1" value="1">
							1분
						</Select.Option>
						<Select.Option key="5" value="5">
							5분
						</Select.Option>
						<Select.Option key="15" value="15">
							15분
						</Select.Option>
						<Select.Option key="30" value="30">
							30분
						</Select.Option>
						<Select.Option key="60" value="60">
							1시간
						</Select.Option>
						<Select.Option key="240" value="240">
							4시간
						</Select.Option>
						<Select.Option key="1440" value="1440">
							1일
						</Select.Option>
					</Select>
				</Form.Item>
			</Form>
			{chartData.data.length > 0 && (
				<ChartCanvas ratio={2} width={800} height={750} margin={{ left: 70, right: 70, top: 20, bottom: 30 }} type={'hybrid'} seriesName="MSFT" data={chartData.data} xScale={chartData.xScale} xAccessor={chartData.xAccessor} displayXAccessor={chartData.displayXAccessor} xExtents={chartData.xExtents}>
					<Chart id={1} height={400} yExtents={(d) => [d.high, d.low]} padding={{ top: 10, bottom: 20 }}>
						<XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} />
						<YAxis axisAt="right" orient="right" ticks={5} tickFormat={currencyFormat} />
						<MouseCoordinateY at="right" orient="right" displayFormat={format('.2f')} />
						<CandlestickSeries />
						<LineSeries yAccessor={(d) => d.pushUpAmount / d.pushUpQuantity} stroke={'#FF0000'}/>
						<LineSeries yAccessor={(d) => d.pullDownAmount / d.pullDownQuantity} stroke={'#00F300'}/>
						<LineSeries yAccessor={(d) => d.averagePrice} stroke={'#4682B4'}/>
						<OHLCTooltip origin={[-40, 0]} />
						<HoverTooltip yAccessor={(d) => d.power} tooltipContent={tooltipContent()} fontSize={15} />
					</Chart>
					<Chart id={2} height={150} yExtents={(d) => d.power} origin={(w, h) => [0, h - 300]} padding={{ top: 10, bottom: 10 }}>
						<XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} />
						<YAxis axisAt="right" orient="right" ticks={2} tickFormat={currencyFormat} />
						<MouseCoordinateY at="right" orient="right" displayFormat={currencyFormat} />
						<MACDSeries
							yAccessor={(d) => {
								return {
									macd: 0,
									signal: 0,
									divergence: d.power
								};
							}}
							{...macdAppearance}
						/>
					</Chart>
					{/* <Chart id={3} height={150} yExtents={(d) => d.cost} origin={(w, h) => [0, h - 300]} padding={{ top: 10, bottom: 10 }}>
						<XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} />
						<YAxis axisAt="left" orient="left" ticks={2} tickFormat={currencyFormat} />
						<MouseCoordinateY at="left" orient="left" displayFormat={currencyFormat} />
						<MACDSeries
							yAccessor={(d) => {
								return {
									macd: d.cost,
									signal: 0,
									divergence: 0
								};
							}}
							{...macdAppearance}
						/>
					</Chart> */}
					<Chart id={4} height={150} yExtents={(d) => d.sumPower} origin={(w, h) => [0, h - 150]} padding={{ top: 10, bottom: 10 }}>
						<XAxis axisAt="bottom" orient="bottom" />
						<YAxis axisAt="right" orient="right" ticks={2} tickFormat={currencyFormat} />
						<MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat('%H:%M:%S')} />
						<MouseCoordinateY at="right" orient="right" displayFormat={currencyFormat} />
						<MACDSeries
							yAccessor={(d) => {
								return {
									macd: 0,
									signal: 0,
									divergence: d.sumPower
								};
							}}
							{...macdAppearance}
						/>
					</Chart>
					<CrossHairCursor />
				</ChartCanvas>
			)}
			{/* <ChartCanvas height={400} width={1900} ratio={3} margin={{ left: 70, right: 70, top: 20, bottom: 30 }} type={'hybrid'} data={data} xScale={xScale} xAccessor={xAccessor} displayXAccessor={displayXAccessor} xExtents={xExtents}>
				<Chart id={1} height={350} padding={{ top: 50, bottom: 0 }} yExtents={(d) => d.premium}>
					<YAxis axisAt="left" orient="left" tickFormat={format(',.2%')} />
					<BarSeries yAccessor={(d) => d.premium} />
				</Chart>
				<Chart id={2} height={350} padding={{ top: 50, bottom: 0 }} yExtents={(d) => [d.upbit_usd, d.binance_usdt]}>
					<YAxis axisAt="right" orient="right" tickFormat={format(',.4f')} />
					<XAxis axisAt="bottom" orient="bottom" />
					<LineSeries yAccessor={(d) => d.upbit_usd} stroke="#003cff" highlightOnHover />
					<LineSeries yAccessor={(d) => d.binance_usdt} stroke="#ff0101" highlightOnHover />
					<HoverTooltip yAccessor={(d) => d.upbit_usd} tooltipContent={tooltipContent()} fontSize={15} />
				</Chart>
				<CrossHairCursor stroke="#cccccc" />
			</ChartCanvas> */}
		</>
	);
};
