import axios from 'axios';
import moment from 'moment';
import { message } from 'antd';

export const getUpbitMarkets = async () => {
	return await axios
		.get('https://api.upbit.com/v1/market/all', {
			params: { isDetails: false }
		})
		.then((response) => response.data.filter((x) => x.market.startsWith('KRW-')))
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getUpbitWallet = async ({ auth, baseUrl, accessKey, secretKey }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3109/getUpbitWallet',
				body: { accessKey, secretKey }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			const upbitWallet = { asset: response.data.find((x) => x.currency === 'KRW') || null, coins: [] };
			upbitWallet.coins = response.data.filter((x) => x.currency !== 'KRW') || [];
			return upbitWallet;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getUpbitProfit = async ({ auth, baseUrl, profitPage, accessKey, secretKey }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3109/getUpbitProfit',
				body: { profitPage, accessKey, secretKey }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('업빗 수익정보를 가져왔습니다.');
			return response.data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getUpbitOrders = async ({ auth, baseUrl, accessKey, secretKey, market, uuids }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3109/getUpbitOrders',
				body: { accessKey, secretKey, market, uuids }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setUpbitOrders = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3109/setUpbitOrders',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('주문이 전송되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setUpbitSetting = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3109/setUpbitSetting',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('업빗 설정이 저장되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setUpbitTriggers = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3109/setUpbitTriggers',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('업빗 조건식이 저장되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getBinanceSymbols = async () => {
	return await axios
		.get('https://api.binance.com/api/v3/exchangeInfo')
		.then((response) => response.data.symbols.filter((x) => x.symbol.endsWith('USDT')))
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getBinanceFuturesSymbols = async () => {
	return await axios
		.get('https://fapi.binance.com/fapi/v1/exchangeInfo')
		.then((response) => response.data.symbols.filter((x) => x.symbol.endsWith('USDT')))
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getBinanceCoinmSymbols = async () => {
	return await axios
		.get('https://dapi.binance.com/dapi/v1/exchangeInfo')
		.then((response) => response.data.symbols.filter((x) => x.symbol.endsWith('USD_PERP')))
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getBinanceWallet = async ({ auth, baseUrl, accessKey, secretKey }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3110/getBinanceWallet',
				body: { accessKey, secretKey }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => response.data.balances.filter((x) => Number(x.free) > 0 || Number(x.locked) > 0))
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getBinanceProfit = async ({ auth, baseUrl, profitPage, accessKey, secretKey }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3110/getBinanceProfit',
				body: { profitPage, accessKey, secretKey }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('바낸 현물 수익정보를 가져왔습니다.');
			return response.data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getBinanceFuturesWallet = async ({ auth, baseUrl, accessKey, secretKey }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3110/getBinanceFuturesWallet',
				body: { accessKey, secretKey }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			const binanceFuturesWallet = { asset: response.data.assets.find((x) => x.asset === 'USDT') || null, positions: [] };
			binanceFuturesWallet.positions = response.data.positions || [];
			binanceFuturesWallet.positions.sort((a, b) => (b.symbol > a.symbol ? -1 : 1));
			return binanceFuturesWallet;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getBinanceFuturesPositionsToTelegramBot = async ({ auth, baseUrl, accessKey, secretKey, telegramBotToken, telegramChatId }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3110/getBinanceFuturesPositionsToTelegramBot',
				body: { accessKey, secretKey }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			if (telegramBotToken && telegramChatId && response.data.telegramMessage) {
				axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
					chat_id: telegramChatId,
					text: response.data.telegramMessage
				});
				message.success('포지션 정보가 텔레그램으로 전송되었습니다.');
			} else {
				message.error('에러가 발생했습니다.');
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getBinanceFuturesTriggersToTelegramBot = async ({ auth, baseUrl, accessKey, secretKey, telegramBotToken, telegramChatId }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3110/getBinanceFuturesTriggersToTelegramBot',
				body: { accessKey, secretKey }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			if (telegramBotToken && telegramChatId && response.data.telegramMessage) {
				axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
					chat_id: telegramChatId,
					text: response.data.telegramMessage
				});
				message.success('조건식 정보가 텔레그램으로 전송되었습니다.');
			} else {
				message.error('에러가 발생하였습니다.');
			}
		})
		.catch((error) => {
			console.log(error);
		});
};

export const getBinanceFuturesProfit = async ({ auth, baseUrl, profitPage, accessKey, secretKey }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3110/getBinanceFuturesProfit',
				body: { profitPage, accessKey, secretKey }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('바낸 선물 수익정보를 가져왔습니다.');
			return response.data;
		})
		.catch((error) => {
			message.error('에러가 발생했습니다.');
			console.log(error);
			return false;
		});
};

export const getBinanceCoinmWallet = async ({ auth, baseUrl, accessKey, secretKey }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3110/getBinanceCoinmWallet',
				body: { accessKey, secretKey }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			const binanceCoinmWallet = { assets: response.data.assets.filter((x) => Number(x.walletBalance) > 0) || [], positions: [] };
			binanceCoinmWallet.positions = response.data.positions.filter((x) => Number(x.positionAmt) !== 0) || [];
			binanceCoinmWallet.assets.sort((a, b) => (b.asset > a.asset ? -1 : 1));
			return binanceCoinmWallet;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getBinanceCoinmProfit = async ({ auth, baseUrl, profitPage, accessKey, secretKey }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3110/getBinanceCoinmProfit',
				body: { profitPage, accessKey, secretKey }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('바낸 코인엠 수익정보를 가져왔습니다.');
			return response.data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getBinanceOrders = async ({ auth, baseUrl, accessKey, secretKey, symbol }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3110/binanceGetOrder',
				body: { accessKey, secretKey, symbol }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceOrders = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceOrders',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('주문이 전송되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceFuturesOrders = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceFuturesOrders',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('주문이 전송되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceFuturesRePositionOrders = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceFuturesRePositionOrders',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('주문이 전송되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceCoinmOrders = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceCoinmOrders',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('주문이 전송되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceCoinmRePositionOrders = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceCoinmRePositionOrders',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('주문이 전송되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceSetting = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceSetting',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('바낸 설정이 저장되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceTriggers = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceTriggers',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('바낸 조건식이 저장되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceFuturesTriggers = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceFuturesTriggers',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('바낸 선물 조건식이 저장되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceCoinmTriggers = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceCoinmTriggers',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('바낸 코인엠 조건식이 저장되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setPremiumOrders = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3111/setPremiumOrders',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('주문이 전송되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const deletePremiumOrder = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3111/deletePremiumOrder',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('주문이 전송되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setPremiumTriggers = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3111/setPremiumTriggers',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('프리미엄 조건식이 저장되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceLeverage = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceLeverage',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('바낸 선물 레버리지가 저장되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceMarginType = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceMarginType',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('바낸 선물 마진타입이 저장되었습니다.');
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceFuturesHedgeMode = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceFuturesHedgeMode',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			if (response.data.status === 'success') {
				message.success('바낸 코인엠 헤지모드가 설정되었습니다.');
			} else {
				message.error(`USDS-M: ${response.data.msg}`);
			}
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBinanceCoinmHedgeMode = async ({ auth, baseUrl, values }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3110/setBinanceCoinmHedgeMode',
				body: values
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			if (response.data.status === 'success') {
				message.success('바낸 코인엠 헤지모드가 설정되었습니다.');
			} else {
				message.error(`Coin-M: ${response.data.msg}`);
			}
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getExchangeRate = async () => {
	return await axios
		.get('https://free.currconv.com/api/v7/convert?q=USD_KRW&compact=ultra&apiKey=cd61f9f70cac6b6df554')
		.then((response) => {
			message.success('환율 정보가 반영되었습니다.');
			return response.data.USD_KRW;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getUsers = async (auth) => {
	return await axios
		.get(`https://${process.env.REACT_APP_REST_API_ENDPOINT}.execute-api.ap-northeast-2.amazonaws.com/dev/getUsers`, {
			headers: {
				Authorization: auth.signInUserSession.accessToken.jwtToken
			},
			params: {}
		})
		.then((response) => response.data)
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getUser = async (auth, Username) => {
	return await axios
		.get(`https://${process.env.REACT_APP_REST_API_ENDPOINT}.execute-api.ap-northeast-2.amazonaws.com/dev/getUser`, {
			headers: {
				Authorization: auth.signInUserSession.accessToken.jwtToken
			},
			params: { Username }
		})
		.then((response) => response.data)
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setUser = async ({ auth, Username, values }) => {
	return await axios
		.post(
			`https://${process.env.REACT_APP_REST_API_ENDPOINT}.execute-api.ap-northeast-2.amazonaws.com/dev/setUser`,
			{
				Username,
				UserAttributes: [
					{
						Name: 'custom:expiry',
						Value: moment(values['custom:expiry']).format('YYYY-MM-DD')
					},
					{ Name: 'custom:upbit', Value: values['custom:upbit'] ? 'true' : 'false' },
					{ Name: 'custom:binance', Value: values['custom:binance'] ? 'true' : 'false' },
					{ Name: 'custom:premium', Value: values['custom:premium'] ? 'true' : 'false' },
					{ Name: 'custom:baseURL', Value: values['custom:baseURL'] || 'http://localhost' },
					{ Name: 'custom:referer', Value: values['custom:referer'] || '' },
					{ Name: 'custom:point', Value: values['custom:point'] ? values['custom:point'].toString() : '0' },
					{ Name: 'custom:memo', Value: values['custom:memo'] || '' },
					{ Name: 'custom:name', Value: values['custom:name'] || '' },
					{ Name: 'custom:version', Value: values['custom:version'] || 'v2' }
				]
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('업데이트 성공.');
			return response.data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const setBasicSetting = async ({ auth, baseUrl, serverUrl, upbit, binance, expiry, point }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				admin: true,
				method: 'post',
				path: ':3105/setBasicSetting',
				body: {
					serverUrl,
					upbit,
					binance,
					expiry: moment(expiry).endOf('day'),
					point
				}
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('사용자 정보가 업데이트 되었습니다.');
			return response.data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getStore = async ({ auth, baseUrl, key }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'get',
				path: ':3105/getStore',
				body: { key }
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const serverRestart = async ({ auth, baseUrl }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3105/serverRestart',
				body: {}
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('서버가 재시작되었습니다.');
			return response.data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const serverReboot = async ({ auth, baseUrl, serverUrl }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				method: 'post',
				path: ':3105/serverReboot',
				body: {
					serverUrls: [serverUrl]
				}
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('재부팅이 요청되었습니다. 약 1분정도 소요됩니다.');
			return response.data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const serverUpdate = async ({ auth, baseUrl, serverUrl }) => {
	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				admin: true,
				method: 'post',
				path: ':3105/serverUpdate',
				body: {
					serverUrls: [serverUrl]
				}
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			message.success('업데이트가 요청되었습니다. 약 10초가 소요됩니다.');
			return response.data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const serverUpdateAll = async ({ auth, baseUrl, activeUsers }) => {
	const serverUrls = [];

	for (const user of activeUsers) {
		if (user.Attributes.find((x) => x.Name === 'custom:baseURL')) {
			const serverUrl = user.Attributes.find((x) => x.Name === 'custom:baseURL').Value;
			if (serverUrl !== '' && serverUrl !== 'http://localhost') {
				serverUrls.push(serverUrl);
			}
		}
	}

	return await axios
		.post(
			`${baseUrl}/relayRequest`,
			{
				admin: true,
				method: 'post',
				path: ':3105/serverUpdate',
				body: {
					serverUrls
				}
			},
			{
				headers: {
					Authorization: auth.signInUserSession.accessToken.jwtToken
				}
			}
		)
		.then((response) => {
			if (response.data && response.data.failedServer) {
				message.error(`${response.data.failedServer} failed. Check the servers.`);
			} else {
				message.success('모든 서버가 업데이트 되었습니다.');
			}
			return response.data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const getTrades = async (auth, division) => {
	return await axios
		.get(`https://${process.env.REACT_APP_REST_API_ENDPOINT}.execute-api.ap-northeast-2.amazonaws.com/dev/getTrades`, {
			headers: {
				Authorization: auth.signInUserSession.accessToken.jwtToken
			},
			params: { division }
		})
		.then((response) => response.data)
		.catch((error) => {
			console.log(error);
			return false;
		});
};
